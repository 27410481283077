@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {

    body {
        @apply text-[#37474F] bg-[#FBFBFB] tracking-tight;
    }

    .sub-nav {
        height: calc(100% - theme("spacing.16"));
    }

    a {
        @apply link;
    }

    h1 {
        @apply text-[#595959] font-medium tracking-tighter text-2xl mb-4 sm:text-3xl xl:mb-6 xl:text-4xl;
    }

    input, textarea {
        @apply outline-none rounded px-3 py-1;
    }

    .link {
        @apply cursor-pointer;
    }

    .btn {
        @apply px-4 py-1.5 rounded border-2 flex items-center justify-center hover:no-underline;
    }

    .btn-primary {
        @apply btn bg-[#3B59C3] text-white border-[#3B59C3] hover:bg-[#2542A7] hover:border-[#2542A7] active:shadow-inner-lg;
    }

    .btn-secondary {
        @apply btn bg-none text-[#6779BA] border-[#6779BA] hover:bg-[#3B59C3] hover:border-[#3B59C3] hover:text-white active:shadow-inner-lg;
    }

    .btn-tertiary {
        @apply btn bg-none text-[#3B59C3] border-transparent hover:bg-[#dbdbdb] hover:border-[#dbdbdb] hover:text-[#3B59C3] active:bg-[#B3BBD6] active:border-[#B3BBD6];
    }

    .menu-gradient {
        background: linear-gradient(91.03deg, #3B6DC3 5.82%, #4E42D9 91.15%);
    }

    .edit-section {
        @apply mt-12 pt-10 border-t border-[#e8e8e8];
    }

    .edit-heading {
        @apply font-medium text-xl mb-4 md:text-2xl;
    }

    .no-scroll {
        -ms-overflow-style: none;
        scrollbar-width: none;
    }

    .no-scroll::-webkit-scrollbar {
        display: none;
    }

    .info {
        @apply border-l-4 border-[#6A81D2] pl-1;
    }

    @keyframes progress-bar-stripes {
        from  { background-position: 3rem 0; }
        to    { background-position: 0 0; }
    }
}

@font-face {
    font-family: "Basier Circle";
    font-style: normal;
    font-weight: 400;
    src: local("Basier Circle"), url("fonts/basiercircle-regular-webfont.ttf") format("opentype");
}

@font-face {
    font-family: "Basier Circle";
    font-style: normal;
    font-weight: 500;
    src: local("Basier Circle"), url("fonts/basiercircle-medium-webfont.ttf") format("opentype");
}

@font-face {
    font-family: "Basier Circle";
    font-style: normal;
    font-weight: 600;
    src: local("Basier Circle"), url("fonts/basiercircle-semibold-webfont.ttf") format("opentype");
}

@font-face {
    font-family: "Basier Circle";
    font-style: normal;
    font-weight: 700;
    src: local("Basier Circle"), url("fonts/basiercircle-bold-webfont.ttf") format("opentype");
}


@font-face {
    font-family: "Basier Circle";
    font-style: italic;
    font-weight: 400;
    src: local("Basier Circle"), url("fonts/basiercircle-regularitalic-webfont.ttf") format("opentype");
}

@font-face {
    font-family: "Basier Circle";
    font-style: italic;
    font-weight: 500;
    src: local("Basier Circle"), url("fonts/basiercircle-mediumitalic-webfont.ttf") format("opentype");
}

@font-face {
    font-family: "Basier Circle";
    font-style: italic;
    font-weight: 600;
    src: local("Basier Circle"), url("fonts/basiercircle-semibolditalic-webfont.ttf") format("opentype");
}

@font-face {
    font-family: "Basier Circle";
    font-style: italic;
    font-weight: 700;
    src: local("Basier Circle"), url("fonts/basiercircle-bolditalic-webfont.ttf") format("opentype");
}



@font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 100;
    src: local("Inter"), url("fonts/Inter-Thin.ttf") format("opentype");
}

@font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 200;
    src: local("Inter"), url("fonts/Inter-ExtraLight.ttf") format("opentype");
}

@font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 300;
    src: local("Inter"), url("fonts/Inter-Light.ttf") format("opentype");
}

@font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    src: local("Inter"), url("fonts/Inter-Regular.ttf") format("opentype");
}

@font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    src: local("Inter"), url("fonts/Inter-Medium.ttf") format("opentype");
}

@font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    src: local("Inter"), url("fonts/Inter-SemiBold.ttf") format("opentype");
}

@font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    src: local("Inter"), url("fonts/Inter-Bold.ttf") format("opentype");
}

@font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 800;
    src: local("Inter"), url("fonts/Inter-ExtraBold.ttf") format("opentype");
}

@font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 900;
    src: local("Inter"), url("fonts/Inter-Black.ttf") format("opentype");
}